// If you don't want to use TypeScript you can delete this file!
import * as React from "react";
import { useMatch } from "@reach/router";
import { graphql, PageProps } from "gatsby";

import { Layout } from "../components/Layout";
import { SEO } from "../components/SEO";
import { AudioCircle } from "../components/AudioCircle";

import * as styles from "../styles/pages/Audio.module.scss";

interface DataProps {
  markdownRemark: {
    frontmatter: {
      files: Array<{
        file: {
          publicURL: string;
        };
      }>;
    };
  };
}

const AudioPage: React.FC<PageProps<DataProps>> = ({
  data,
  path,
  location,
}) => {
  const match = useMatch("/audio/:audioId");

  const audioFile = React.useMemo(() => {
    if (match?.audioId) {
      return data.markdownRemark.frontmatter.files[
        parseInt(match.audioId, 10) - 1
      ];
    }
  }, [data, match]);

  return (
    <Layout trimmed>
      <SEO title="Audio" />
      {audioFile && (
        <div className={styles.audio}>
          <AudioCircle
            className={styles.player}
            audio={audioFile.file.publicURL}
          />
        </div>
      )}
    </Layout>
  );
};

export const query = graphql`
  query AudioPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/audiopage.md/" }) {
      frontmatter {
        files {
          file {
            publicURL
          }
        }
      }
    }
  }
`;

export default AudioPage;
